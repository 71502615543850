<template>
  <div id="MGM">
    <WhereWeAreMap :mapData="mapData" />
  </div>
</template>

<script>
import WhereWeAreMap from '@/components/global/WhereWeAreMap'
import { getMGMMapData } from "@/data/sectionData/whereWeAreData/mGMMapData"

export default {
  name: "MGM",
  components: {
    WhereWeAreMap
  },
  data() {
    return {
      mapData: getMGMMapData()
    }
  }
}
</script>

<style lang="scss scoped">

</style>